<template>
  <div class="page404-F4">
    <div class="galaxy">
      <div class="card">
        <div class="header">
          <h1>404</h1>
          <p> {{ $t("404.pagefound") }}</p>
        </div>
        <div class="body">
          <p>{{ $t("404.pagecontentone") }}<a href='https://www.taoke-energy.com/'
               target="_blank">{{ $t("404.pagecontenttwo") }}</a>{{ $t("404.pagecontentthree") }}</p>
          <div class="mt-4">
            <a href="javascript:history.go(-1)"
               class="btn btn-default btn-lg btn-round mr-3"><span>{{ $t("404.backgo") }}</span></a>
            <a href="home"
               class="btn btn-stealth btn-lg btn-round"><span>{{ $t("404.backhome") }}</span></a>
          </div>
        </div>
      </div>
      <div class="sandland">
        <div class="SkyF2 mr-2">
          <img src="../assets/images/404/SkyF2.svg"
               class="img-fluid">
        </div>
        <div class="LandF2">
          <img src="../assets/images/404/LandF2.svg"
               class="img-fluid">
        </div>
      </div>
      <div class="star comet"></div>
    </div>
  </div>
</template>

<script>
$(function () {
  const wH = window.innerHeight;
  const wW = window.innerWidth;
  var x = document.getElementsByClassName("page404-F4");

  const generateStars = n => {
    for (let i = 0; i < n; i++) {
      const div = document.createElement('div');
      div.className = i % 20 == 0 ? 'star star--big' : i % 9 == 0 ? 'star star--medium' : 'star';
      // random everywhere!
      div.setAttribute('style', `top:${Math.round(Math.random() * wH)}px;left:${Math.round(Math.random() * wW)}px;animation-duration:${Math.round(Math.random() * 3000) + 3000}ms;animation-delay:${Math.round(Math.random() * 3000)}ms;`);
      x[0].appendChild(div);
    }
  };

  generateStars(150);
})

</script>

<style>
.page404-F4 .btn-lg {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.page404-F4 .btn:hover,
.page404-F4 .btn:focus {
  -moz-box-shadow: 0 0 18px 0 #8bcec9;
  -webkit-box-shadow: 0 0 18px 0 #8bcec9;
  box-shadow: 0 0 18px 0 #8bcec9;
}

.page404-F4 .btn-stealth {
  color: var(--color-white);
  opacity: 0.75;
  background-color: transparent;
  -moz-box-shadow: 0 0 18px 0 #8bcec9;
  -webkit-box-shadow: 0 0 18px 0 #8bcec9;
  box-shadow: 0 0 18px 0 #8bcec9;
}

.page404-F4 .btn-stealth:hover,
.page404-F4 .btn-stealth:focus {
  color: var(--color-white);
  opacity: 1;
}
</style>
